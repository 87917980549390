<template>
	<div
		id="encontrar-o-agente-certo"
		class="modal fade"
		tabindex="-1"
		role="dialog"
		aria-labelledby="Econtrar Agente"
		aria-hidden="true"
	>
		<div class="modal-dialog modal-dialog-scrollable modal-fullscreen">
			<div class="modal-content">
				<div class="modal-header">
					<button
						type="button"
						class="btn-close"
						data-bs-dismiss="modal"
						aria-hidden="true"
					/>
				</div>

				<div class="modal-body mb-0 text-black">
					<div id="container-content" class="container">
						<h4 class="modal-title fs-title fw-heavy text-center text-black">
							Encontre o agente certo!
						</h4>

						<template v-if="step > 0">
							<div class="mb-3">
								<MarkStep :current-step="step" />
							</div>

							<div class="mb-5">
								<template v-for="comp in stepFiltered">
									<component
										:is="comp.name"
										v-bind="comp.props"
										:key="comp.name"
										ref="currentComponent"
										v-model="form"
									/>
								</template>
							</div>
							<div
								v-if="step > 0"
								class="d-flex justify-content-between justify-content-md-center"
							>
								<a
									href="javascript:void(0)"
									rel="nofollow"
									class="btn btn-light-blue mr-2"
									data-dismiss="modal"
									@click="prevStep"
								>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 24 24"
										width="20"
										height="20"
										fill="currentColor"
									>
										<path d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z" />
									</svg>
									Voltar
								</a>
								<a
									href="javascript:void(0)"
									rel="nofollow"
									class="btn btn-primary"
									@click="nextStep"
								>
									Confirmar
									<svg
										xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 24 24"
										fill="currentColor"
										width="20"
										height="20"
									>
										<path d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z" />
									</svg>
								</a>
							</div>
						</template>
						<template v-else>
							<div class="row justify-content-center">
								<div class="col-12 col-md-8 col-lg-6">
									<p class="text-center mb-0 mt-5 fw-regular fs-content text-black line-height-25">
										Está com dificuldades a vender, comprar ou arrendar um imóvel?
										Nós ajudamo-lo a encontrar o agente certo para si!
										Obtenha apoio de um consultor imobiliário ou uma agência certificada para
										concretizar os seus negócios imobiliários.
									</p>

									<div class="text-center my-5">
										<svg
											xmlns="http://www.w3.org/2000/svg"
											xmlns:xlink="http://www.w3.org/1999/xlink"
											width="93.634"
											height="118.786"
											viewBox="0 0 93.634 118.786"
										>
											<defs>
												<clipPath id="clip-path">
													<rect
														id="Rectangle_1013"
														data-name="Rectangle 1013"
														width="74.686"
														height="106.492"
														fill="none"
													/>
												</clipPath>
											</defs>
											<g
												id="Group_7188"
												data-name="Group 7188"
												transform="translate(0 14.251) rotate(-11)"
											>
												<g
													id="Group_7163"
													data-name="Group 7163"
													clip-path="url(#clip-path)"
												>
													<path
														id="Path_4446"
														data-name="Path 4446"
														d="M74.187,100.856l-26.153-45.6A30.481,30.481,0,1,0,41.9,58.622l25.743,45.945a3.763,3.763,0,1,0,6.547-3.712m-51.3-43.483A28.016,28.016,0,1,1,57.343,37.818,28.016,28.016,0,0,1,22.885,57.373"
														transform="translate(0 0)"
														fill="#1072ee"
													/>
													<path
														id="Path_4447"
														data-name="Path 4447"
														d="M25.279,74.759a.564.564,0,0,1-.082-.072l-.231-.222c-.2-.2-.509-.477-.863-.879A25.27,25.27,0,0,1,21.332,70a27.891,27.891,0,0,1-1.541-2.727,28.282,28.282,0,0,1-1.356-3.329,25.849,25.849,0,0,1-.118-16.22,26.384,26.384,0,0,1,2.808-6.1,25.229,25.229,0,0,1,2.718-3.622c.348-.407.657-.687.85-.891l.227-.226A.5.5,0,0,1,25,36.819a.585.585,0,0,1-.07.084l-.216.237c-.185.212-.487.5-.827.91a26.619,26.619,0,0,0-2.668,3.641,26.688,26.688,0,0,0-2.764,6.083A25.942,25.942,0,0,0,18.576,63.9a26.707,26.707,0,0,0,2.852,6.041,26.628,26.628,0,0,0,2.721,3.6c.346.407.652.689.84.9l.219.234a.536.536,0,0,1,.071.084"
														transform="translate(-11.787 -25.422)"
														fill="#e0e0e0"
													/>
													<path
														id="Path_4448"
														data-name="Path 4448"
														d="M60.6,20.958a6.157,6.157,0,0,1-.821.326c-.509.192-1.205.473-1.953.83s-1.4.726-1.871,1a6.159,6.159,0,0,1-.768.436,3.954,3.954,0,0,1,.708-.532,15.974,15.974,0,0,1,3.847-1.843,3.958,3.958,0,0,1,.858-.218"
														transform="translate(-38.108 -14.469)"
														fill="#e0e0e0"
													/>
												</g>
											</g>
										</svg>
									</div>

									<p class="text-center">
										<a
											class="btn btn-primary btn-lg"
											rel="nofollow"
											href="javascript:void(0)"
											@click="step = 1"
										>
											Iniciar
											<svg
												xmlns="http://www.w3.org/2000/svg"
												viewBox="0 0 24 24"
												fill="currentColor"
												width="20"
												height="20"
											>
												<path d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z" />
											</svg>
										</a>
									</p>
								</div>
							</div>
						</template>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import MarkStep from "@/pages/publicpages/agent/wizard/MarkStep.vue";
import Step1 from "@/pages/publicpages/agent/wizard/Step1.vue";
import Step2 from "@/pages/publicpages/agent/wizard/Step2.vue";
import Step3 from "@/pages/publicpages/agent/wizard/Step3.vue";
import Step4 from "@/pages/publicpages/agent/wizard/Step4.vue";
import Step5 from "@/pages/publicpages/agent/wizard/Step5.vue";
import {isObject} from "lodash";

export default {
    name: "ModalWizard",
    components: {
        MarkStep,
        Step1,
        Step2,
        Step3,
        Step4,
        Step5,
    },
    data() {
        return {
            form: {
                typeBusiness: 'other-property', //'my-property' or 'other-property'
                purpose: 'rent', //'sell', 'rent' or sell-or-rent
                locality: null,
                price: null,
                property_type: 'Casa ou Apartamento',
            },
            step: 0,
        }
    },
    computed: {
        stepComponents() {
            return [
                {
                    name: 'Step1',
                    props: {
                        step: 1,
                        title: 'Eu quero ajuda para:',
                    }
                },
                {
                    name: 'Step2',
                    props: {
                        step: 2,
                        title: this.form.typeBusiness === 'my-property' ? 'Como deseja comercializar o seu imóvel?' : 'Qual a finalidade do imóvel que procura?',
                    }
                },
                {
                    name: 'Step3',
                    props: {
                        step: 3,
                        title: 'Detalhes sobre o imóvel',
                    }
                },
                {
                    name: 'Step4',
                    props: {
                        step: 4,
                        title: 'Tipo de imóvel',
                    }
                },
                {
                    name: 'Step5',
                    props: {
                        step: 5,
                        title: 'Procurando agente!',
                    }
                },
            ]
        },
        stepFiltered() {
            return this.stepComponents.filter(comp => comp.props.step === this.step)
        }
    },
    methods: {
        nextStep() {
            if (!this.$refs.currentComponent[0]?.validate()) {
                return;
            }

            if (this.step === 5) {
                return;
            }

            this.step++;

            if (this.step === 5) {
                let params = new URLSearchParams();

                for (const key in this.form) {
                    if (HelperJS.hasProperty(this.form, key)) {
                        const element = this.form[key];
                        if (isObject(element)) {
                            for (const key2 in element) {
                                if (HelperJS.hasProperty(element, key2)) {
                                    const element2 = element[key2];
                                    params.append(key + '[' + key2 + ']', element2);
                                }
                            }
                        } else {
                            params.append(key, element);
                        }
                    }
                }

                window.location.href = route('agents') + '?' + params.toString();
            }
        },
        prevStep() {
            if (this.step <= 0) {
                return;
            }

            this.step--;
        },
    }
}
</script>

<style lang="scss">
.line-height-25 {
    line-height: 25px;
}

.line-height-35 {
    line-height: 35px;
}

@media screen and (max-width: 768px) {
    #container-content {
        padding: 0;

        .fs-content {
            --fs-content: 16px;
        }
    }
}
</style>

<style lang="scss">
#encontrar-o-agente-certo {
    .modal-dialog {
        width: 100%;
    }

    p {
        font-size: 16px;
        line-height: 25px;
    }

    .new-public-pages-first-view-content {
        //padding:12% 6% 12% 6%;
        padding-left: 60px;
    }

    .new-public-pages-first-view-content {
        background-color: #FAFAFA;
    }

    .new-public-pages-first-view-content h1 {
        font-size: 70px;
        font-weight: 900;
        line-height: 75px;
        margin-bottom: 30px;
    }

    h2 {
        font-size: 40px;
        line-height: 65px;
        font-weight: 600;
        margin-bottom: 15px;
    }

    .new-public-pages-first-view-content {
        color: #FAFAFA;
    }

    p.new-public-pages-first-view-text {
        font-size: 18px;
        line-height: 25px;
        margin-bottom: 30px;
        color: #2C2C2C;
        font-weight: normal;
    }

    .new-public-pages-first-view-content .btn {
        font-size: 16px;
        line-height: 22px;
    }

    .new-public-pages-content-section {
        padding-top: 30px;
    }

    .new-public-pages-content-section h2 {
        color: #2C2C2C;
        font-size: 40px;
        line-height: 75px;
        font-weight: 900;
        margin-bottom: 0;
    }

    .new-public-pages-content-section-text {
        font-size: 16px;
        line-height: 25px;
        margin-bottom: 30px;
        color: #707070;
        font-weight: normal;
    }

    .new-public-pages-content-section-card-inner {
        background-color: #ffffff;
        padding-right: 10px;
        padding-left: 10px;
        min-height: 425px;
        padding-top: 15px;
        padding-bottom: 15px;
        border-radius: 6px;
    }

    .new-public-pages-content-section-cards-noback .new-public-pages-content-section-card-inner {
        background-color: unset;
    }

    .new-public-pages-content-section-cards-noback::before {
        content: " ";
        background-color: #0a53be;
        position: relative;
        left: 12%;
        right: 12%;
        top: 121px;
        display: inline-block;
        height: 3px;
        width: 76%;
        z-index: 1;
    }

    .new-public-pages-content-section-card {
        padding: 10px;
        text-align: center;
        z-index: 99;

        @media screen and (max-width: 337px) {
            padding-left: 5px;
        }
    }

    .new-public-pages-content-section-card-title {
        font-size: 24px;
        line-height: 25px;
        font-weight: 900;
        margin-bottom: 10px;
        margin-top: 20px;
        color: #0190f8;
    }

    .new-public-pages-content-section-card img {
        width: 170px;
        height: 170px;
    }

    .new-public-pages-content-section-card-inner p {
        font-size: 16px;
        line-height: 25px;
    }

    .new-public-cta-stripe h4 {
        font-size: 40px;
        line-height: 25px;
        font-weight: 900;
        margin-top: 16px;
        margin-bottom: 20px;
        color: #2C2C2C;
    }

    .new-public-cta-stripe .btn {
        font-size: 16px;
        line-height: 22px;
    }

    .new-public-cta-stripe {
        padding-bottom: 45px;
    }

    @media only screen and (max-width: 1080px) {
        .new-public-pages-content-section-cards-noback::before {
            display: none;
        }
    }

    @media only screen and (max-width: 768px) {
        .new-public-pages-first-view {
            background-image: url("/images/help-agent-info.webp");
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
        }

        .new-public-pages-first-view-image {
            opacity: 1;
        }

        .new-public-pages-content-section-card img {
            width: 111px;
            height: 111px;
        }

        .new-public-pages-content-section-card-title {
            align-self: center;
            margin-top: 0px;
            margin-bottom: 0px;
        }

        .new-public-pages-content-section-card-inner {
            min-height: 50px;
        }

        .new-public-pages-content-section-img-title {
            padding-bottom: 15px;
        }

        .new-public-pages-first-view-content h1 {
            font-size: 40px;
            line-height: 40px;
        }

        h2, .new-public-pages-content-section h2 {
            font-size: 24px;
            line-height: 35px;
        }

        .new-public-pages-content-section-text {
            font-size: 16px;
            line-height: 30px;
        }
    }

    @media only screen and (max-width: 468px) {
        .new-public-pages-content-section-card-title {
            font-size: 18px;
        }
    }

    .new-public-pages-first-view-title {
        color: #1072EE;
    }

    .new-public-pages-first-view-subtitle {
        color: #2C2C2C;
    }

    .line-height-25 {
        line-height: 25px;
    }

    .img-people {
        width: 181px;
    }

    .multiselect--active {
        z-index: 99;
    }
    #container-content .multiselect__option{
        white-space: initial !important;
    }
}

</style>
