<template>
	<div>
		<p class="fs-content fw-semi-bold text-black text-center mb-5 line-height-35">
			{{ title }}
		</p>

		<div class="row justify-content-center">
			<div class="col-12 col-md-10">
				<div class="row justify-content-center">
					<div class="col-12 col-md-6 col-xl-4 mb-3">
						<grouped-errors :errors="errors" />
					</div>
				</div>

				<div class="row justify-content-center">
					<div class="col-12 col-md-6 col-xl-4 mb-3">
						<label>Zona</label>
						<multiselect
							v-model="proxyForm.locality"
							placeholder="Localidade do imóvel"
							track-by="api_id"
							:custom-label="customLabel"
							:options="areas"
							:internal-search="false"
							:clear-on-select="false"
							:reset-after="false"
							:close-on-select="true"
							:preserve-search="true"
							@search-change="fetchLocation"
						>
                            <span slot="noOptions"> Digite a zona </span>
                            <span slot="noResult"> Sem resultados </span>
                        </multiselect>
					</div>
				</div>
				<div class="row justify-content-center">
					<div class="col-12 col-md-6 col-xl-4">
						<label>Preço</label>
						<CurrencyInput
							v-model="proxyForm.price"
							currency="EUR"
							locale="pt"
							placeholder="Preço máximo do imóvel"
						/>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import CurrencyInput from "@/vue/components/CurrencyInput.vue";
import GroupedErrors from "@/vue/components/GroupedErrors.vue";

export default {
    name: "Step3",
    components: {
        CurrencyInput,
        GroupedErrors
    },
    model: {
        prop: "form",
        event: "change"
    },
    props: {
        form: {
            type: Object,
            default: () => ({
                locality: null,
                price: null,
            })
        },
        title: {
            type: String,
            default: "Detalhes sobre o imóvel"
        }
    },
    data(){
        return {
            areas: [],
            errors: [],
        }
    },
    computed: {
        proxyForm: {
            get() {
                return this.form;
            },
            set(value) {
                this.$emit("update:form", value);
                this.$emit('change', value);
            }
        }
    },
    mounted() {
        //this.fetchLocation('')
    },
    methods: {
        fetchLocation(params) {
            axios.post(route('api.fetchLocations'), {
                term: params,
                perPage: 8
            }).then(({ data }) => {
                this.areas = data
            })
        },
        customLabel({ Label, Name }) {
            return `${Name} (${Label})`
        },
        validate(){
            this.errors = [];

            if (! this.proxyForm.locality) {
                this.errors.push('A Zona é obrigatória')
            }

            return this.errors.length === 0;
        }
    }
}
</script>

<style scoped>
p.text-black.fs-content {
    color: #333333;
}
</style>
