<template>
	<div
		class="card card-choice"
		:data-card="name"
		:class="{active: active}"
		@click="handleClickCard"
	>
		<div class="card-body d-flex align-items-center">
			<div class="row">
				<slot>
					<p class="fs-content fw-heavy text-center text-break">
						{{ title }}
					</p>
					<p class="fs-sub-title fw-regular text-center text-break">
						{{ description }}
					</p>
				</slot>
			</div>
		</div>
		<div class="card-footer d-none d-md-inline-block">
			<div class="d-flex justify-content-center">
				<label class="container-checkbox">
					<input
						:id="`input-choice-${name}`"
						class="form-check-input fs-content checkbox-effect-4"
						type="checkbox"
						:name="`check-choice-${name}`"
						:value="name"
						:checked="proxyValue === name"
						@change="toggle"
					>
					<span class="checkmark" />
				</label>
			</div>
		</div>
	</div>
</template>

<script>
export default {
    name: "CardChoice",
    model: {
        prop: "value",
        event: "change"
    },
    props: {
        name: {
            type: String,
            required: true
        },
        title: {
            type: String,
            required: true
        },
        description: {
            type: String,
            required: true
        },
        value: {
            type: String,
            required: false,
            default: null
        },
        active: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    computed: {
        proxyValue: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("change", value);
            }
        }
    },
    methods: {
        handleClickCard(card) {
            this.$emit('click-card', card);
        },
        toggle() {
            this.proxyValue = this.name;
            this.$emit('toggle', this.name);
        }
    }
}
</script>

<style scoped lang="scss">

//transitions mixin
@mixin transition-mix($property: all, $duration: 0.1s, $timing: linear, $delay: 0s) {
    transition-property: $property;
    transition-duration: $duration;
    transition-timing-function: $timing;
    transition-delay: $delay;
}

.card-choice {
    border-radius: 6px;
    min-height: 345px;
    cursor: pointer;
    background-color: #FAFAFA;
    border: 4px solid #FAFAFA;

    .card-body {
        align-self: center;
    }

    .card-footer {
        border-top: 0;
        background-color: #FAFAFA;
        padding: 0;
        margin-bottom: 3rem;
    }
}

@media screen and (max-width: 768px) {
    .card-choice {
        min-height: 266px;
        border: 2px solid #707070;
        --fs-content: 18px;
    }
    .card-choice.active {
        border: 4px solid #0172EE;
    }

    .fs-sub-title {
        --fs-sub-title: 16px;
        color: #464545;
        line-height: 19px;
    }
}

@media screen and (max-width: 425px) {
    .card-choice {
        min-height: 157px;
    }
}

p.text-black.fs-content {
    color: #333333;
}

/* Hide the browser's default checkbox */
.container-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    height: 25px;
    width: 25px;
    background-color: #FFF;
    border-radius: 50%;
    right: 44%;
    border: 1.5px solid #707070;
}

/* On mouse-over, add a grey background color */
.container-checkbox:hover input ~ .checkmark {
    background-color: #ccc;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Style the checkmark/indicator */
.container-checkbox .checkmark:after {
    left: 9px;
    top: 3px;
    width: 8px;
    height: 15px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}



.card-choice.active {
    border: 4px solid #1072EE;
    background-color: #FFFFFF;

    .card-footer {
        background-color: #FFFFFF;
    }

    /* When the checkbox is checked, add a blue background */
    .container-checkbox input ~ .checkmark {
        background-color: #1072EE;
        border: 0;
        @include transition-mix($delay: 0.05s);
    }

    /* Show the checkmark when checked */
    .container-checkbox input ~ .checkmark:after {
        display: block;
    }

    @include transition-mix($delay: 0.05s);
}

</style>
