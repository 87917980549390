import ModalWizard from '@/pages/publicpages/agent/wizard/Index';
Vue.component('ModalWizard', ModalWizard);

new Vue({
    el: '#wrapper-modal-wizard',
});



const priceoptionsDefault = JSON.parse(document.querySelector('[name="priceoptionsDefault"]').value);
const propertyTypes = JSON.parse(document.querySelector('[name="propertyTypes"]').value);

window.vm = new Vue({
    el: '#app2',
    name: 'Home',
    components: {
        'FormAutocomplete': {
            props: {
                isMultiple: {
                    type: Boolean,
                    default: false,
                },
                placeholder: {
                    type: String,
                    default: 'Escolha uma Localização',
                },
                value: {
                    type: [Object, Array, String],
                    default() {
                        return {
                            api_id: undefined,
                            Label: undefined,
                            Name: undefined,
                        };
                    },
                },

            },
            data() {
                return {
                    textSearch: '',
                    highlightedPosition: 0,
                    isShow: false,
                    test: [],
                    concelhos: [],
                }
            },
            computed: {
                selectedItems() {
                    if (this.isMultiple) {
                        return this.value.map(v => v.uid);
                    }
                    return [];
                },
            },

            watch: {
                textSearch: function (newQuestion, oldQuestion) {
                    this.searchLocation()
                },
            },
            mounted() {
            },
            methods: {
                searchLocation() {
                    if (this.textSearch.length > 2) {
                        //run showOption hehere instead of v-on:keyup
                        axios.get(route('locations_vue') + "?locais=" + this.textSearch + "&format=json")
                            .then(response => {
                                this.test = response.data
                                sessionStorage.setItem(`seearch:${this.textSearch}`, JSON.stringify(this.test))
                            })
                        // if (sessionStorage.getItem(`seearch:${this.textSearch}`)) {
                        //     this.test = JSON.parse(sessionStorage.getItem(`seearch:${this.textSearch}`));
                        // } else {
                        //     axios.get(route('locations_vue') + "?locais=" + this.textSearch + "&format=json")
                        //         .then(response => {
                        //             this.test = response.data
                        //             sessionStorage.setItem(`seearch:${this.textSearch}`, JSON.stringify(this.test))
                        //         })
                        // }
                        console.log(this.test );
                    }
                },
                moveDown() {
                    if (!this.isShow) {
                        return
                    }
                    console.log(this.highlightedPosition);
                    this.highlightedPosition =
                        (this.highlightedPosition + 1) % this.test.length
                },
                moveUp() {
                    if (!this.isShow) {
                        return
                    }
                    this.highlightedPosition = this.highlightedPosition - 1 < 0 ? this.test.length - 1 : this.highlightedPosition - 1
                },

                getConcelhos(distritoId, distritoLabel) {
                    this.districtlabel = distritoLabel;
                    this.districtid = distritoId;
                    axios
                        .get("{{route('locations_vue')}}" + "?locais=" + this.textSearch + "&format=json")
                        .then(response => (this.concelhos = response.data))
                },

                inSelectedItems(api_id) {
                    return this.selectedItems.includes(api_id);
                },

                showOptions() {
                    console.log(this.textSearch);
                    if (this.textSearch.length > 2) {
                        this.isShow = true;
                    } else {
                        this.isShow = false
                    }
                },
                select() {
                    const selectedOption = this.test[this.highlightedPosition]
                    this.textSearch = '';
                    this.isShow = false;
                    // this.$emit('update:item', item);
                    this.$emit('input', selectedOption);
                    this.$emit('onSelectItem', selectedOption);
                },
                selectItem(item) {
                    this.textSearch = '';
                    this.isShow = false;
                    // this.$emit('update:item', item);
                    console.log('there')
                    if (item.id != null && item.id != '' && item.id != 'atuamãenoespeto') {
                        $('#basicSearchForm').find('.searchLocationInputWrapper').css('border', '1px solid #ccc');
                    }

                    this.$emit('input', item);
                    this.$emit('onSelectItem', item);
                },
                clearItem() {
                    this.textSearch = '';

                    console.log(this);
                    this.$emit('input', {
                        api_id: undefined,
                        Label: undefined,
                        Name: undefined,
                    });
                    this.$emit('onClearItem');
                },
                addItem(item) {
                    if (!this.inSelectedItems(item.uid)) {
                        this.textSearch = '';
                        this.isShow = false;
                        const items = JSON.parse(JSON.stringify(this.value));
                        items.push(item);
                        // this.$emit('update:item', items);
                        this.$emit('input', items);
                        this.$emit('onAddItem', items);
                    }
                },
                removeItem(index) {
                    this.textSearch = '';
                    const items = JSON.parse(JSON.stringify(this.value));
                    items.splice(index, 1);
                    // this.$emit('update:item', items);
                    this.$emit('input', items);
                    this.$emit('onRemoveItem', items);
                },
            },
            template:
                `
                    <div class="form-autocomplete col-12 h-100">
                        <div class="h-100" v-if="!isMultiple">
                            <div class="d-flex align-items-center h-100 ___" v-if="!value || !value.api_id">
                                <input
                                    data-element="searchLocationInput"
                                    class="responsive-text-18 color-gray-7070 border-none bg-transparent"
                                    v-on:input="textSearch=$event.target.value"
                                    v-bind:value="textSearch"
                                    type="text"
                                    :placeholder="placeholder"
                                    v-on:keyup="showOptions"
                                    @keydown.down="moveDown"
                                    @keydown.up="moveUp"
                                    @keydown.enter="select"
                                />
                            </div>

                            <div class="h-100">
                                <div class="row justify-content-around" v-if="value && value.api_id">
                                    <div class="col-10 text-1-line" style="line-height: 34px;">
                                        <slot name="selected" :item="value">{{ value.Name }}</slot>
                                    </div>
                                    <div class="col-2 d-flex align-items-center" @click="clearItem">
                                        <img src="/icons/close-thick.svg" alt="Close" height="24" width="24">
                                    </div>
                                </div>
                            </div>
                            <ul class="options autocomplete-options" v-show="isShow">
                                <li v-for="(item, index) in test" @click="selectItem(item)"
                                    :class="{'highlighted': index === highlightedPosition}">
                                    <slot :item="item">{{ item.Name }} ({{ item.Label }})</slot>
                                </li>
                                <li v-if="test.length===0">Não Encontrado</li>
                            </ul>
                            <template v-if="value">
                                <input id="local" v-if="value.Label === 'Zona'" type="hidden" name="zona" v-bind:value="value.api_id">
                                <input id="local" v-if="value.Label === 'Freguesia'" type="hidden" name="parish" v-bind:value="value.api_id">
                                <input id="local" v-if="value.Label === 'Concelho'" type="hidden" name="county" v-bind:value="value.api_id">
                                <input id="local" v-if="value.Label === 'Distrito'" type="hidden" name="district" v-bind:value="value.api_id">
                            </template>
                        </div>
                    </div>
                `
            ,
        }
    },
    data() {
        return {
            countryA: {
                api_id: null,
                Label: null,
                Name: null,
            },
            useRef: false,
            priceoptions: priceoptionsDefault[0],
            maxPrice: false,
            neg: 0,
            negLabel: null,
            propertyTypes: propertyTypes,
            aPropType: null,
            PropTypeId: null,
            PropTypeGroup: null,
            PropTypeName: null,
            showAreas: false,
            showQuartos: 1,
            rooms: undefined,
            roomsLabel: undefined,
            countries: '',
        };
    },
    mounted() {
        this.negClick('Venda', 1);
        this.setPropType('Casas e Apartamentos', 'Casas e Apartamentos', [1, 2, 7])
    },
    methods: {
        toLowerCase(text) {
            return text.toLowerCase();
        },
        chooseNegocio() {
            console.debug('chooseNegocio');
        },
        choosePrice(value, Label) {
            this.maxPrice = [];
            this.maxPrice[0] = value;
            this.maxPrice[1] = Label;
        },
        negClick: function (label, type) {
            this.useRef = false;
            this.negLabel = label;
            console.log(this.negLabel)
            if (type === 0) {
                this.neg = 1;
                this.maxPrice = false;
                this.priceoptions = priceoptionsDefault[0];
            } else {
                this.neg = 0;
                this.maxPrice = false;
                this.priceoptions = priceoptionsDefault[1];
            }
        },
        setPropType: function (group, name, id) {
            this.PropTypeGroup = group;
            this.PropTypeName = name;
            this.PropTypeId = id;

            if (this.PropTypeName === "Quarto") {
                this.showAreas = true;
                this.$nextTick(() => this.showQuartos = false);
            } else if (this.PropTypeGroup === "1") {
                this.showQuartos = true;
                this.$nextTick(() => this.showAreas = false);
            } else {
                this.showAreas = true;
                this.$nextTick(() => this.showQuartos = false);
            }
        },
        chooseRooms: function (val, label) {
            this.roomsLabel = label;
            this.rooms = val;
        },

        changeUseRef: function (searchType) {
            var currentValue = this.useRef;
            if (searchType == 'ref') {
                if (currentValue == false) {
                    this.useRef = true;
                }
            } else {
                if (currentValue == true) {
                    this.useRef = false;
                }
            }
        },
    },
});

//Não sei porque.... Maaaaaas não funciona ir pelo Vue.
//Tive de fazer isso para resolver
//Já era um problema que existia, maaas, nunca se queixaram
document.querySelectorAll('#pills-tab [data-valor]').forEach(function (el) {
    el.addEventListener('click', (e) => {
        let label = el.getAttribute('data-valor');
        let type = el.getAttribute('data-price');
        vm.negClick(label, type);
    })
})

$(document).ready(function () {
    let getElImgForAd = $('.get-me-img-for-ad');
    let randomImgMax = getElImgForAd.length;

    let randomImg = Math.floor(Math.random() * randomImgMax) + 1;
    randomImg = randomImg - 1;

    let newImg = getElImgForAd.eq(randomImg).attr('data-fakeimg');

    $('.ads-img-ex-inner').css('background-image', 'url(' + newImg + ')');

    setTimeout(function () {
        $('.wrap-back-and-search').addClass('effect');
    }, 1600);

    $('.easy-autocomplete-container').addClass('notranslate');

    $('nav a').click(function () {
        $('nav a').removeClass("active");
        $(this).addClass("active");
        // $(this).addClass("extra-bold");
    });
});
