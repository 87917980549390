<template>
	<input
		ref="inputRef"
		type="text"
		class="form-control"
	>
</template>

<script>
import { useCurrencyInput } from 'vue-currency-input'

export default {
    name: 'CurrencyInput',
    props: {
        value: {
            type: Number,
            default: null
        },
        options: {
            type: Object,
            default: () => ({})
        }
    },
    setup(props) {
        const { inputRef } = useCurrencyInput(Object.assign({
            locale: "pt-BR",
            currency: "EUR",
            currencyDisplay: "symbol",
            precision: false,
            hideCurrencySymbolOnFocus: false,
            hideGroupingSeparatorOnFocus: false,
            hideNegligibleDecimalDigitsOnFocus: true,
            autoDecimalDigits: false,
            autoSign: true,
            useGrouping: true,
            accountingSign: false
        }, props.options))

        return { inputRef }
    }
}
</script>