<template>
	<div id="step-1">
		<p class="fs-content fw-semi-bold text-black text-center mb-5">
			{{ title }}
		</p>

		<div class="row justify-content-center">
			<div class="col-12 col-md-10">
				<div class="row justify-content-center">
					<div class="col-12 col-md-6 col-lg-4 mb-3">
						<CardChoice
							v-model="proxyForm.typeBusiness"
							name="my-property"
							title="Arrendar ou vender o meu imóvel"
							description="Sou proprietário de um imóvel e estou à procura de um agente que me ajude a vender ou a arrendar"
							:active="proxyForm.typeBusiness === 'my-property'"
							@click-card="handleClickCard('my-property')"
						/>
					</div>
					<div class="col-12 col-md-6 col-lg-4 mb-3">
						<CardChoice
							v-model="proxyForm.typeBusiness"
							name="other-property"
							title="Encontrar um imóvel para comprar ou arrendar"
							description="Estou à procura de um imóvel para comprar ou arrendar e quero a ajuda de um agente"
							:active="proxyForm.typeBusiness === 'other-property'"
							@click-card="handleClickCard('other-property')"
						/>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import CardChoice from "@/pages/publicpages/agent/wizard/CardChoice.vue";

export default {
    name: "Step1",
    components: {CardChoice},
    model: {
        prop: "form",
        event: "change"
    },
    props: {
        form: {
            type: Object,
            default: () => ({
                typeBusiness: "other-property",
            })
        },
        title: {
            type: String,
            default: "O que procura?"
        }
    },
    computed: {
        proxyForm: {
            get() {
                return this.form;
            },
            set(value) {
                this.$emit("update:form", value);
                this.$emit('change', value);
            }
        }
    },
    methods: {
        /**
         *
         * @param $event {Event|string}
         */
        toggle($event) {
            document.querySelectorAll('#step-1 .card-choice').forEach((el) => {
                el.classList.remove('active');
            });

            if (typeof $event === 'string') {
                document.querySelector(`[data-card="${$event}"]`).classList.add('active');
            } else {
                $event.target.closest('#step-1 .card-choice').classList.add('active');
            }
        },
        handleClickCard(typeBusiness) {
            this.proxyForm.typeBusiness = typeBusiness;
            this.toggle(typeBusiness);
        },
        validate(){
            return true;
        }
    }
}
</script>

<style scoped>
</style>
