<template>
	<div>
		<p class="fs-content fw-semi-bold text-black text-center mb-5">
			{{ title }}
		</p>

		<div class="row justify-content-center">
			<div class="col-12 col-md-8 col-lg-6">
				<grouped-errors :errors="errors" />

				<div
					v-for="pType in propertyTypes"
					:key="pType.name"
					class="row"
				>
					<div class="col mb-3">
						<div
							class="card card-property-type"
							:data-card-property-type="pType.name"
							:class="{active: proxyForm.property_type === pType.name}"
							@click="handleClickCard(pType.name)"
						>
							<div class="card-body d-flex justify-content-between align-items-center">
								<div class="my-hammer">
									<p class="mb-0">
										{{ pType.name }}
									</p>
								</div>
								<div class="">
									<!--									<input-->
									<!--										v-model="proxyForm.property_type"-->
									<!--										type="radio"-->
									<!--										:value="pType.name"-->
									<!--										class="form-check-input fs-content"-->
									<!--									>-->
									<label class="container-checkbox">
										<input
											:id="`input-choice-${pType.name}`"
											class="form-check-input fs-content checkbox-effect-4"
											type="checkbox"
											:name="`check-choice-${pType.name}`"
											:value="pType.name"
											:checked="proxyForm.property_type === pType.name"
											@change="toggle"
										>
										<span class="checkmark" />
									</label>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import GroupedErrors from "@/vue/components/GroupedErrors.vue";

export default {
    name: "Step4",
    components: {
        GroupedErrors
    },
    model: {
        prop: "form",
        event: "change"
    },
    props: {
        form: {
            type: Object,
            default: () => ({
                property_type: 'Casa ou Apartamento',
            })
        },
        title: {
            type: String,
            default: "Detalhes sobre o imóvel"
        }
    },
    data(){
        return {
            propertyTypes: [
                {id: 1, name: "Casa ou Apartamento"},
                {id: 2, name: "Espaço comercial, Escritório ou Armazém"},
                {id: 3, name: "Terreno"},
            ],
            errors: [],
        }
    },
    computed: {
        proxyForm: {
            get() {
                return this.form;
            },
            set(value) {
                this.$emit("update:form", value);
                this.$emit('change', value);
            }
        }
    },
    methods: {
        validate(){
            this.errors = [];

            if (!this.proxyForm.property_type) {
                this.errors.push("Por favor selecione o tipo de imóvel.");
            }

            return this.errors.length === 0;
        },
        /**
         *
         * @param $event {Event|string}
         */
        toggle($event) {
            document.querySelectorAll('.card-property-type').forEach((el) => {
                el.classList.remove('active');
            });

            if (typeof $event === 'string') {
                document.querySelector(`[data-card-property-type="${$event}"]`).classList.add('active');
            } else {
                $event.target.closest('.card-property-type').classList.add('active');
            }
        },
        handleClickCard(propertyType) {
            this.proxyForm.property_type = propertyType;
            //this.toggle(propertyType);
        },
    }
}
</script>

<style scoped lang="scss">
//transitions mixin
@mixin transition-mix($property: all, $duration: 0.1s, $timing: linear, $delay: 0s) {
    transition-property: $property;
    transition-duration: $duration;
    transition-timing-function: $timing;
    transition-delay: $delay;
}

.card {
    background-color: #FAFAFA;
    border: 4px solid #FAFAFA;
    border-radius: 6px;
    min-height: 102px;
}

/* Hide the browser's default checkbox */
.container-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    height: 25px;
    width: 25px;
    background-color: #FFF;
    border-radius: 50%;
    right: 10%;
    border: 1.5px solid #707070;
    bottom: 35%;
}

/* On mouse-over, add a grey background color */
.container-checkbox:hover input ~ .checkmark {
    background-color: #ccc;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Style the checkmark/indicator */
.container-checkbox .checkmark:after {
    left: 9px;
    top: 3px;
    width: 8px;
    height: 15px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.card.active {
    border: 4px solid #1072EE;
    background-color: #FFFFFF;

    /* When the checkbox is checked, add a blue background */
    .container-checkbox input ~ .checkmark {
        background-color: #1072EE;
        border: 0;
        @include transition-mix($delay: 0.05s);
    }

    /* Show the checkmark when checked */
    .container-checkbox input ~ .checkmark:after {
        display: block;
    }

    @include transition-mix($delay: 0.05s);
}

p.text-black.fs-content {
    color: #333333;
}

@media screen and (max-width: 768px) {
    .my-hammer {
        max-width: 70%;
    }
}
</style>
