<template>
	<div class="text-center">
		<p class="text-center">
			<span
				class="spinner-border spinner-border-sm"
				role="status"
				aria-hidden="true"
			/>
			<span class="visually-hidden">Loading...</span>
			Estamos procurando os melhores agentes...
		</p>
	</div>
</template>

<script>
export default {
    name: "Step5"
}
</script>

<style scoped>

</style>
