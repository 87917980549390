<template>
	<div id="steps-encontrar-o-agente-certo" :class="classes">
		<div :class="classesCol">
			<div class="new-public-pages-content-section-cards new-public-pages-content-section-cards-noback col-12 no-padding">
				<div class="row">
					<template v-for="step in maxSteps">
						<div
							:key="step"
							class="new-public-pages-content-section-card col no-padding"
							:class="{active: step === currentStep || step === 1 && currentStep === 1.5}"
						>
							<div class="new-public-pages-content-section-card-inner col-xs-12">
								<div class="col-12 no-padding new-public-pages-content-section-img-title">
									<div class="row">
										<div class="new-public-pages-content-section-card-img col-12 no-padding d-flex justify-content-center">
											<div class="circle">
												<span class="number">{{ step }}</span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</template>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
    name: "MarkStep",
    props: {
        currentStep: {
            type: Number,
            default: 1
        },
        maxSteps: {
            type: Array,
            default: () => ([1, 2, 3, 4, 5])
        },
        classes: {
            type: String,
            default: "row justify-content-center"
        },
        classesCol: {
            type: String,
            default: 'col-12 col-md-8 col-lg-6'
        }
    },
}
</script>

<style scoped lang="scss">
.circle {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: white;
    color: #1072EE;
    border: 3px #1072EE solid;
    font-size: 20px;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
}

.new-public-pages-content-section-card.active {
    .circle {
        background-color: #1072EE;
        color: white;
        border-color: #1072EE;
    }
}

#steps-encontrar-o-agente-certo {
    .new-public-pages-content-section-cards-noback::before {
        top: 60px;
        display: inline-block;
        background-color: #1072EE;
    }

    .new-public-pages-content-section-card-inner {
        min-height: 80px;
    }
}
</style>
