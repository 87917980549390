<template>
	<div>
		<p class="fs-content fw-semi-bold text-black text-center mb-5">
			{{ title }}
		</p>

		<div class="row justify-content-center">
			<div class="col-12 col-md-10">
				<div class="row justify-content-center">
					<div class="col-12 col-lg-4 col-xl-3 mb-3">
						<CardChoice
							v-model="proxyForm.purpose"
							name="sell"
							title="Vender"
							description="Apenas quero ver agentes especializados em venda de imóveis"
							@click-card="handleClickCard('sell')"
							@toggle="toggle"
						/>
					</div>
					<div class="col-12 col-lg-4 col-xl-3 mb-3">
						<CardChoice
							v-model="proxyForm.purpose"
							name="rent"
							title="Arrendar"
							description="Apenas quero ver agentes especializados em arrendamento de imóveis"
							@click-card="handleClickCard('rent')"
							@toggle="toggle"
						/>
					</div>
					<div class="col-12 col-lg-4 col-xl-3 mb-3">
						<CardChoice
							v-model="proxyForm.purpose"
							name="sell-or-rent"
							title="Tanto vender ou arrendar"
							description="Quero ver ambos os agentes especializados em venda e os especializados em arrendamento"
							@click-card="handleClickCard('sell-or-rent')"
							@toggle="toggle"
						/>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import CardChoice from "@/pages/publicpages/agent/wizard/CardChoice.vue";
export default {
    name: "Step2",
    components: {CardChoice},
    model: {
        prop: "form",
        event: "change"
    },
    props: {
        form: {
            type: Object,
            default: () => ({
                purpose: 'rent'
            })
        },
        title: {
            type: String,
            default: "O que procura?"
        }
    },
    computed: {
        proxyForm: {
            get() {
                return this.form;
            },
            set(value) {
                this.$emit("update:form", value);
                this.$emit('change', value);
            }
        }
    },
    mounted() {
        this.toggle(this.proxyForm.purpose);
    },
    methods: {
        /**
         *
         * @param $event {Event|string}
         */
        toggle($event) {
            document.querySelectorAll('.card-choice').forEach((el) => {
                el.classList.remove('active');
            });

            if (typeof $event === 'string') {
                document.querySelector(`[data-card="${$event}"]`).classList.add('active');
            } else {
                $event.target.closest('.card-choice').classList.add('active');
            }
        },
        handleClickCard(purpose) {
            this.proxyForm.purpose = purpose;
            this.toggle(purpose);
        },
        validate(){
            return true;
        }
    },
}
</script>

<style scoped>
p.text-black.fs-content {
    color: #333333;
}
</style>
