<template>
	<div class="col-12 px-0">
		<div v-if="errors.length > 0" id="grouped-errors">
			<div class="alert alert-danger">
				<h4>{{ title }}</h4>
				<ul v-cloak>
					<li
						v-for="(error, index) in errors"
						:key="index"
						v-html="error"
					/>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
import {isArray, isObject, isString, isUndefined} from "lodash";
import {isUndefinedOrNull} from "tupy-helperjs";

export default {
    name: 'GroupedErrors',
    props: {
        errors: {
            type: Array,
            required: false,
            default: () => ([])
        },

        title: {
            type: String,
            required: false,
            default: 'Whoops!'
        }
    },
    data() {
        return {
            internalErrors: [],
        }
    },

    watch: {
        errors(value) {
            this.internalErrors = value
        }
    },
    //
    // created(){
    // 	if (this.errors) {
    // 		this.internalErrors = this.errors;
    // 	}
    // },

    methods: {
        async set(error, scrollTop = true){
            await this.clear()

            if (isUndefined(error)) {
                this.internalErrors.push('Não foi possível atender o pedido');

                if (scrollTop) {
                    this.scrollTo()
                }

                return this.internalErrors;
            }

            if (error.response && 'data' in error.response) {
                const data = error.response.data;

                if ('errors' in data && isObject(data.errors)) {
                    await this.setErrorWithMarkHtml(data.errors)
                } else if ('message' in data) {
                    this.internalErrors.push(data.message)
                } else if (isString(data)) {
                    this.internalErrors.push(data)
                } else {
                    this.internalErrors.push(error)
                }
            } else if (isObject(error)) {
                await this.setErrorWithMarkHtml(error)
            } else if (isArray(error)) {
                console.debug('is array?')
                this.internalErrors = error;

                if (scrollTop) {
                    this.scrollTo()
                }

                return this.internalErrors;
            } else {
                this.internalErrors.push(error)
            }

            if (scrollTop) {
                this.scrollTo()
            }

            return this.internalErrors;
        },
        async setErrorWithMarkHtml(errors){
            let element;
            await Object.keys(errors).forEach(key => {
                let text;

                if (isArray(errors[key])) {
                    text = errors[key][0]
                } else if (isString(errors[key])) {
                    text = errors[key]
                } else {
                    text = "Inválido"
                }

                element = document.getElementById(key);

                //Quando for um input multiselect... busco a div principal
                try {
                    let forMultiselect = element.closest('.multiselect');
                    if (! isUndefinedOrNull(forMultiselect)) {
                        element = forMultiselect;
                    }
                } catch (e) {
                    // ignore
                }

                if (! isUndefinedOrNull(element)) {
                    if (! element.classList.contains('parsley-error')) {
                        element.classList.add('parsley-error')
                    }

                    let helperText = document.createElement('small')
                    helperText.classList.add('text-danger')
                    helperText.setAttribute('data-validator', key)
                    helperText.innerText = HelperJS.removeTags(text);
                    element.insertAdjacentElement('afterend', helperText)
                }

                this.internalErrors.push(text)
            })
        },
        async clear(){
            this.internalErrors = [];

            let elementsWithError = document.querySelectorAll('.parsley-error')

            if (!isUndefinedOrNull(elementsWithError) && elementsWithError.length > 0) {
                await elementsWithError.forEach(element => {
                    element.classList.remove('parsley-error');
                })
            }

            let elementsHelperText = document.querySelectorAll('[data-validator]')

            if (!isUndefinedOrNull(elementsHelperText)) {
                await elementsHelperText.forEach(element => {
                    element.remove()
                })
            }

            return Promise.resolve(true);
        },
        scrollTo(){
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
            })
        }
    }
};
</script>
